<template>
<!--
<div id="app">
  <div id="nav">
    <router-link to="/Register">Register</router-link> |
    <router-link to="/">LogIn</router-link>
    <router-view/>
  </div>
</div>
-->
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'App',

};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}
</style>
