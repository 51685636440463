<template>
  <v-card
    class="mx-auto overflow-hidden"
    height=100vh
    width=auto

  >
    <v-app-bar
      color="deep-purple"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-tabs
        fixed-tabs
        background-color="indigo"
        dark
      >
        <v-tab>
          Dashboard
        </v-tab>
        <v-tab>
          Templates
        </v-tab>
        <v-tab>
          Edits
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      width=auto
    >
      <v-img
        class="mx-2"
        alt="toorbee"
        src="@/assets/toorbee_sq.svg"
        max-height="40"
        max-width="40"
        contain
      ></v-img>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-file</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-video</v-icon>
            </v-list-item-icon>
          </v-list-item>
          
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-image</v-icon>
            </v-list-item-icon>
          </v-list-item>
          
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-music</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>>

<script>
export default {
  name: 'NavBar',
  data: () => ({
    drawer: false,
    group: null,
    }),
}
</script>

<style>

</style>