<template>
  <body class=hero-image>
    <v-card class="mx-auto mt5 card">
      <v-img
      class="logo-img mx-auto mar-logo"
      alt="Toorbee Logo"
      src="@/assets/logo.svg"
      contain
      ></v-img>
      <v-card-text class="mar-logo">
      <h1 class="text-center">Sign in</h1>
      </v-card-text>
      <v-card-text>
      <p class="body-1 text-center">Welcome, please use the form to sign-in to Toorbee</p>
      <v-form @submit.prevent="login">
        <v-flex xs1>
          <v-subheader>Email</v-subheader>
        </v-flex>
        <v-text-field
        v-model="email"
        label="Type your email..."
        solo
        />
        <v-flex xs1>
          <v-subheader>Password</v-subheader>
        </v-flex>
        <v-text-field
        v-model="password"
        :type="showPassword ? 'text' : 'password'"
        label="Type your password..."
        solo
        :append-icon="showPassword ? 'mdi-eye' :
        'mdi-eye-off'"
        @click:append="showPassword =!showPassword"
        />
        <v-checkbox
        v-model="checkbox"
        :label="`Remember me: ${checkbox.toString()}`"
        ></v-checkbox>
        <v-container>
          <v-row>
            <v-col>
              <v-btn type="submit" color="info">Login</v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
            <v-btn>
              <a href="#" class="secondary-button">Forget Password?</a>
            </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      </v-card-text>
    </v-card>
  </body>
</template>

<script>

export default {
  name: 'Login',

  data() {
    return {
      showPassword: false,
      checkbox: true,
      email: '',
      password: ''
    }
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password
      })
      .then( () => {
        this.$router.push({ name: 'dashboard' })
      })
    }
  }
};
</script>

<style>
.card {
  width: 30%;
  height: 100%;
  min-width: 400px
  }

.logo-img {
  width: 350px;
  height: 20%;
}

.mar-card {
  margin: 0 10%;
}

.mar-info {
  margin: 0 20%;
}
.hero-image {
  background-image: url("../assets/toorbee-login-background.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100%; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
</style>