<template>
  <body>
    <NavBar />
  </body>
</template>

<script>
import axios from 'axios'
import EventCard from '../components/EventCard'
import NavBar from '../components/NavBar'

export default {
    components: { EventCard, NavBar },
    data () {
      return {
        isLoading: true,
        events: []
      }
    },
    created () {
      axios.get('//localhost:8080/dashboard').then(({ data }) => {
        this.events = data.events.events
        this.isLoading = false
      })
    }
}
</script>